import React from "react";
import PropTypes from "prop-types";
import { navigate } from "@reach/router";

const languageNames = {
  en: "English",
  fi: "Suomi",
  sv: "Svenska",
  ru: "Русский",
  "zh-hans": "中文(简体)",
  et: "Eesti",
};

const LanguageSwitcher = ({ languages, icao_code, location }) => {
  return (
    <div className="language-switcher">
      {languages.map(language => (
        <button
          key={language}
          className="link-button language-button"
          onClick={() =>
            navigate(
              `/${icao_code.toLowerCase()}/${language}/${location.search}`
            )
          }
        >
          {languageNames[language]}
        </button>
      ))}
    </div>
  );
};

LanguageSwitcher.propTypes = {
  languages: PropTypes.array,
  icao_code: PropTypes.string,
  location: PropTypes.object,
};

export default LanguageSwitcher;
