import React from "react";
import PropTypes from "prop-types";
import FinaviaLogoIcon from "../svgs/finavia_logo.svg";

const Header = ({ airportName }) => (
  <div className="header-bar">
    <div className="rw-grid">
      <div className="rw-row">
        <div className="rw-col--sm-6 header">
          <div className="logo">
            <FinaviaLogoIcon />
          </div>
          <div className="airport-name">
            <h1>{airportName}</h1>
          </div>
        </div>
      </div>
    </div>
  </div>
);

Header.propTypes = {
  airportName: PropTypes.string,
};

Header.defaultProps = {
  airportName: ``,
};

export default Header;
