import React, { useEffect, useState, useRef } from "react";
import { sendLoginEventToAnalytics } from "../utils/analyticsHelpers";
import { I18n } from "@lingui/react";
import { t } from "@lingui/macro";

const LoginForm = ({ redirectUrl, location }) => {
  const [switchUrl, setSwitchUrl] = useState("");
  const formEl = useRef(null);
  useEffect(() => {
    if (typeof window !== `undefined`) {
      const params = new URLSearchParams(window.location.search);
      if (params.has("switch_url")) {
        setSwitchUrl(params.get("switch_url"));
      } else {
        // if there is no switch_url parameter, set the redirect
        /// url so that we can check which page the user would be redirected to.
        setSwitchUrl(redirectUrl);
      }
    }
  }, [redirectUrl]);

  const handleSubmit = async e => {
    e.preventDefault();
    await sendLoginEventToAnalytics({ path: location.pathname });
    formEl.current.submit();
  };

  return (
    <I18n>
      {({ i18n }) => (
        <form
          ref={formEl}
          className="connect-form"
          method="post"
          action={switchUrl}
          onSubmit={handleSubmit}
        >
          <input type="hidden" name="buttonClicked" value="4" />
          <input type="hidden" name="err_flag" value="0" />
          <input type="hidden" name="redirect_url" value={redirectUrl} />
          <input
            className="rw-button rw-button--primary connect-button"
            type="submit"
            value={i18n._(t("connect")`Connect`)}
          ></input>
        </form>
      )}
    </I18n>
  );
};

export default LoginForm;
