import React, { useRef } from "react";
import PropTypes from "prop-types";
import { I18n } from "@lingui/react";
import { t } from "@lingui/macro";
import ReactModal from "react-modal";
import Cross from "../svgs/cross.svg";

// needed for accessibility:
ReactModal.setAppElement("#___gatsby");

const Modal = ({ isOpen, toggleModal, title, children, ...rest }) => {
  const closeModalRef = useRef(null);

  // This function improves VoiceOver behaviour by trapping focus *inside* the modal,
  // instead of on the modal element itself. This prevents a case where the user
  // opens a modal, but finds themselves navigating the underlying content instead.
  const focusCloseButton = () => {
    if (requestAnimationFrame) {
      requestAnimationFrame(() => closeModalRef?.current.focus());
    }
  };

  return (
    <I18n>
      {({ i18n }) => (
        <ReactModal
          className="modal"
          overlayClassName="modal-wrapper"
          bodyOpenClassName="modal-open"
          contentLabel={title}
          isOpen={isOpen}
          onAfterOpen={focusCloseButton}
          onRequestClose={toggleModal}
          {...rest}
        >
          <div className="modal-header">
            <h1 className="modal-title">{title}</h1>
            <button
              ref={closeModalRef}
              className="modal-close"
              onClick={toggleModal}
              aria-label={i18n._(t("Close modal")`Close modal`)}
            >
              <Cross />
            </button>
          </div>
          <div className="modal-content">{children}</div>
        </ReactModal>
      )}
    </I18n>
  );
};

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]).isRequired,
};

export default Modal;
