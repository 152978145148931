import React from "react";
import { graphql } from "gatsby";
import Helmet from "react-helmet";
import Img from "gatsby-image";
import Layout from "../components/Layout";
import Header from "../components/Header";
import LoginForm from "../components/LoginForm";
import TermsOfUse from "../components/TermsOfUse";
import LanguageSwitcher from "../components/LanguageSwitcher";

const AirportLoginPageTemplate = ({ data: { airport }, location }) => {
  const {
    name,
    fields,
    wifi_welcome_text,
    wifi_terms_of_use,
    wifi_redirect_url,
    languages,
    icao_code,
    langcode,
  } = airport;

  return (
    <Layout langcode={langcode}>
      <Helmet
        title={`Finavia wi-fi login - ${name}`}
        htmlAttributes={{
          lang: langcode,
        }}
      />
      <Header airportName={name} />

      <div className="rw-grid">
        <div className="rw-row">
          <div className="rw-col--sm-6 rw-col--md-3 rw-col--xl-5">
            {fields?.wifi_image_mobile && (
              <Img
                className="airport-image airport-image__mobile"
                fluid={fields.wifi_image_mobile.childImageSharp.fluid}
              />
            )}
            {fields?.wifi_image_desktop && (
              <Img
                className="airport-image airport-image__desktop"
                fluid={fields.wifi_image_desktop.childImageSharp.fluid}
              />
            )}
          </div>
          <div className="rw-col--sm-6 rw-col--md-5 rw-col--xl-6 rw-col--offset-xl-1">
            <div className="login-page-contents">
              <h2 className="welcome-text">{wifi_welcome_text}</h2>
              <TermsOfUse termsOfUseMarkup={wifi_terms_of_use} />
              <LoginForm redirectUrl={wifi_redirect_url} location={location} />
              <LanguageSwitcher
                location={location}
                languages={languages}
                icao_code={icao_code}
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AirportLoginPageTemplate;

export const query = graphql`
  query AirportPageTemplate($id: String!) {
    airport(id: { eq: $id }) {
      id
      wifi_welcome_text
      wifi_terms_of_use
      wifi_redirect_url
      icao_code
      langcode
      name
      languages
      fields {
        wifi_image_mobile {
          childImageSharp {
            fluid(maxWidth: 750) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        wifi_image_desktop {
          childImageSharp {
            fluid(maxWidth: 750) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
