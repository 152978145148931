import React, { useState } from "react";
import PropTypes from "prop-types";
import { I18n } from "@lingui/react";
import { Trans, t } from "@lingui/macro";
import Modal from "./Modal";

const TermsOfUse = ({ termsOfUseMarkup }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const toggleModal = () => {
    setModalIsOpen(!modalIsOpen);
  };
  return (
    <I18n>
      {({ i18n }) => {
        return (
          <div className="terms-of-use">
            <Modal
              isOpen={modalIsOpen}
              toggleModal={toggleModal}
              title={i18n._(t("Terms and conditions")`Terms and conditions`)}
            >
              {termsOfUseMarkup && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: termsOfUseMarkup,
                  }}
                />
              )}
            </Modal>

            <p>
              <Trans>
                By connecting you agree with our{" "}
                <button className="link-button" onClick={toggleModal}>
                  terms and conditions
                </button>
              </Trans>
            </p>
          </div>
        );
      }}
    </I18n>
  );
};

TermsOfUse.propTypes = {
  termsOfUseMarkup: PropTypes.string,
};

TermsOfUse.defaultProps = {
  termsOfUseMarkup: ``,
};

export default TermsOfUse;
